const dataServers = {
  b: {
    name: 'Server B',
    baseUrl: process.env.REACT_APP_MIDDLEWARE_URL,
    authServer: true,
    default: true,
    uploadsContainer: '/files'
  },
  a: {
    name: 'Server A',
    baseUrl: "https://data-a.carto4ch.huma-num.fr/",
  }
};

export default dataServers;
